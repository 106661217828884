
import { defineComponent, ref, reactive, nextTick } from "vue";
import { message } from "ant-design-vue";
import moment, { Moment } from "moment";
import { getBuList, getAreasDate } from "@/API/checking/claimDelayRuleSetup";
import {
  getDelayCallbackTableData,
  saveDelayCallbackComments,
  getDelayCallbackDetail,
  updateDelayCallbackComments,
  deleteDelayCallbackComments,
  getRegionsbyBuList,
} from "@/API/delayCallback/index";
import {
  DelayCallbackParamType,
  DelayCallbackParamInfoType,
} from "@/views/DelayCallback/types";
import NoticeModal from "@/components/NoticeModal.vue";
import cleanEmptyEleArray from "@/utils/rv/cleanEmptyElement";
import setColumnWidth from "@/utils/rv/setColumnWidth";
import { useStore } from "vuex";
const tableWidth = window.innerWidth;
const columns = reactive([
  {
    title: "Region",
    dataIndex: "regionName",
    width: 100,
  },
  {
    title: "Province",
    dataIndex: "province",
    width: 120,
  },
  {
    title: "City",
    dataIndex: "city",
    width: 120,
  },
  {
    title: "Dealer Code",
    dataIndex: "dealerCode",
    width: 120,
  },
  {
    title: "Dealer Name",
    dataIndex: "dealerName",
    width: 180,
  },
  {
    title: "VIN",
    dataIndex: "vinNo",
    width: 180,
  },
  {
    title: "Retail Period",
    dataIndex: "retailPeriod",
    width: 180,
    slots: { customRender: "retailPeriod" },
  },
  {
    title: "Business Unit",
    dataIndex: "bu",
    width: 120,
  },
  {
    title: "Status",
    dataIndex: "timeStatus",
    width: 100,
    slots: { customRender: "timeStatus" },
  },
  {
    title: "Delay Deadline",
    dataIndex: "delayDeadline",
    width: 150,
  },
  {
    title: "Operation",
    fixed: "right",
    dataIndex: "operation",
    width: 180,
    slots: { customRender: "operation" },
  },
]);
const columnsDealer = reactive([
  {
    title: "Dealer Code",
    align: "center",
    dataIndex: "dealerCode",
    width: 120,
  },
  {
    title: "Dealer Name",
    align: "center",
    dataIndex: "dealerNameEn",
    width: 120,
  },
]);
const columnsProgram = reactive([
  {
    title: "VIN",
    align: "center",
    dataIndex: "vinNo",
    width: 120,
  },
]);
export default defineComponent({
  components: {
    NoticeModal,
  },
  setup() {
    const tableHeight = ref();
    const searchCon = ref();
    const queryListData = ref([]);
    const regionAble = ref<boolean>(true);
    const cityAble = ref<any>(true);
    const provinceAble = ref<any>(true);
    const nowDate = new Date();
    const params = reactive<DelayCallbackParamType>({
      bu: "",
      region: "",
      regionName: "",
      province: "",
      city: "",
      dealerCode: "",
      dealerCodeList: [],
      vinNo: "",
      vinNoList: [],
      retailPeriod: [],
      delayDeadline: "",
      comments: "",
    });
    const paramsInfo = reactive<DelayCallbackParamInfoType>({
      bu: "",
      region: "",
      regionName: "",
      province: "",
      city: "",
      dealerCode: "",
      dealerCodeList: [],
      vinNo: "",
      vinNoList: [],
      retailPeriodFrom: "",
      retailPeriodTo: "",
      delayDeadline: "",
      comments: "",
    });
    //弹窗显示
    const modalVisible = ref<boolean>(false);
    const modalData = reactive({});
    const modalParams = reactive({
      noticeType: "",
      content: "",
      btnVisible: false,
    });
    //分页组件
    const pagination = reactive({
      pageSize: 20,
      currentPage: 0,
      total: 0,
    });
    const nowTiem = moment().format("YYYY/MM/DD");
    const nowItemData = ref<any>({});
    const nowDealerLstItem = ref<any>({
      dealerCodeList: [],
      vinList: [],
      status: "",
    });
    const areasArr = ref<any>([]);
    const nowCityLst = ref<any>([]);
    const regionSelect = ref<any>([]);
    const store = useStore();
    const businessUnitSelect = ref<any>([]);
    const editDeadLine = ref<any>("");
    const codeModalInf = reactive<any>({
      isCover: false,
      codeModalTit: "",
      codeModalLstTit: "",
    });
    const textContentNo = ref<string>("");
    const commentsLastValue = ref<any>("");
    const viewCove = ref<any>(false);
    const editCove = ref<any>(false);
    const dealerCove = ref<any>(false);
    const deleteId = ref<number>(0);
    const dealerDisabled = ref<boolean>(false);

    const disabledDate = (date: Moment) => {
      return date >= moment().endOf("day");
    };
    const getTableScroll = () => {
      nextTick(() => {
        //  默认底部分页42 + 边距10
        const extraHeight = 160;
        const tHeader = document.getElementsByClassName("table-box")[0];
        //表格内容距离顶部的距离
        let tHeaderBottom = 0;
        if (tHeader) {
          tHeaderBottom = tHeader.getBoundingClientRect().top;
        }
        //窗体高度-表格内容顶部的高度-表格内容底部的高度
        const height = `calc(100vh - ${tHeaderBottom + extraHeight}px)`;
        tableHeight.value = height;
        queryListData.value = [];
        if (!(queryListData.value && queryListData.value.length > 0)) {
          (
            document.getElementsByClassName("ant-table-placeholder")[0] as any
          ).style.height = `calc(100vh - ${tHeaderBottom + 52}px)`;
        }
      });
    };

    const getTableData = () => {
      const params = {
        current: pagination.currentPage,
        size: pagination.pageSize,
      };
      getDelayCallbackTableData({ params }).then((res: any) => {
        queryListData.value = res.content;
        pagination.total = res.totalElements;
        const customerColumn = ["dealerCode"];
        queryListData.value.length &&
          Object.assign(
            columns,
            setColumnWidth(columns, queryListData.value, customerColumn)
          );
      });
      getTableScroll();
    };
    const doGetSelect = () => {
      // const data = ref<any>([]);
      // const buList = store.state.user.buList;
      // buList.forEach((item: any) => {
      //     data.value.push({ text: item.nameCn, value: item.nameCn });
      // });
      // businessUnitSelect.value = data.value;
      const buList = store.state.user.buList;
      businessUnitSelect.value = buList.filter((bu: any) => {
        return bu.parentId === null;
      });
      getAreasDate().then((res) => {
        areasArr.value = res;
      });
    };

    const buIdNameMap = store.state.user.buList.reduce((m: any, item: any) => {
      const name = item.nameCn;
      const id = item.id;
      return { ...m, [name]: id };
    }, {});
    console.log("buIdNameMap", buIdNameMap);
    const regionMap = {};
    const dogetRegion = () => {
      // const bulist: number[] = store.state.user.buList.map((item: any) => {
      //   return item.id;
      // });
      getRegionsbyBuList({
        params: { buIds: buIdNameMap[params.bu] },
      }).then((res: any) => {
        const regionArray: any = [];
        if (res.code === "0") {
          res.data.forEach((item: any) => {
            item.regionDto.forEach((itemCld: any) => {
              const obj = Object.assign({});
              obj.key = item?.id + "_" + itemCld?.id;
              obj.code = item?.id + "_" + itemCld?.id;
              obj.msg = item?.nameEn + "_" + itemCld?.subRegionEn;
              regionArray.push(obj);
              regionMap[obj.key] = obj.msg;
            });
          });
          regionSelect.value = regionArray;
          console.log("regionMap", regionMap);
        } else {
          message.error(res.message);
        }
      });
    };
    const init = () => {
      doGetSelect();
      getTableData();
    };
    init();
    const resetParams = () => {
      params.bu = "";
      params.region = "";
      params.regionName = "";
      params.province = "";
      params.city = "";
      params.dealerCode = "";
      params.dealerCodeList = [];
      params.vinNo = "";
      params.vinNoList = [];
      params.retailPeriod = [];
      params.delayDeadline = "";
      params.comments = "";
      pagination.pageSize = 20;
      pagination.currentPage = 0;
    };
    const getBUForm = () => {
      if (params.bu) {
        regionAble.value = false;
        provinceAble.value = true;
        cityAble.value = true;
        dogetRegion();
      } else {
        regionAble.value = true;
        provinceAble.value = true;
        cityAble.value = true;
      }
      params.region = "";
      params.province = "";
      params.city = "";
    };
    const getRegionForm = () => {
      if (params.region) {
        provinceAble.value = false;
        cityAble.value = true;
      } else {
        provinceAble.value = true;
        cityAble.value = true;
      }
      params.province = "";
      params.city = "";
    };
    const getForm = () => {
      if (params.province) {
        areasArr.value.forEach((ele: any) => {
          if (params.province == ele.provinceEn) {
            nowCityLst.value = ele.cityList;
          }
        });
        cityAble.value = false;
      } else {
        cityAble.value = true;
      }
      params.city = "";
    };
    const isDealerDisabled = () => {
      if (params.region || params.province || params.city) {
        dealerDisabled.value = true;
        params.dealerCode = "";
        params.dealerCodeList = [];
      } else {
        dealerDisabled.value = false;
      }
    };
    //显示的VIN数组
    const textNoArray = ref<any>([]);
    const getParams = () => {
      paramsInfo.bu = "";
      paramsInfo.region = "";
      paramsInfo.regionName = "";
      paramsInfo.province = "";
      paramsInfo.city = "";
      paramsInfo.dealerCode = "";
      paramsInfo.dealerCodeList = [];
      paramsInfo.vinNo = "";
      paramsInfo.vinNoList = [];
      paramsInfo.retailPeriodFrom = "";
      paramsInfo.retailPeriodTo = "";
      paramsInfo.delayDeadline = "";
      paramsInfo.comments = "";

      if (params.bu) {
        paramsInfo.bu = params.bu;
      }
      if (params.region) {
        paramsInfo.region = params.region;
        paramsInfo.regionName = regionMap[params.region];
      }
      if (params.province) {
        paramsInfo.province = params.province;
      }
      if (params.city) {
        paramsInfo.city = params.city;
      }
      if (params.dealerCode) {
        paramsInfo.dealerCode = params.dealerCode.trim();
      }
      if (params.dealerCodeList) {
        paramsInfo.dealerCodeList = cleanEmptyEleArray(params.dealerCodeList);
      }
      if (params.vinNo) {
        paramsInfo.vinNo = params.vinNo.trim();
      }
      if (params.vinNoList.length > 0) {
        paramsInfo.vinNoList = cleanEmptyEleArray(params.vinNoList);
      }
      if (params.retailPeriod.length > 0) {
        paramsInfo.retailPeriodFrom = params.retailPeriod[0];
        paramsInfo.retailPeriodTo = params.retailPeriod[1];
      }
      if (params.delayDeadline) {
        paramsInfo.delayDeadline = params.delayDeadline;
      }
      if (params.comments) {
        paramsInfo.comments = params.comments.trim();
      }
    };
    //program code & dealer code
    //空占位
    const batchPlaceholder = ref(["", "", "", "", "", ""]);
    // 设置批量查询modal 的 占位div
    const setBatchPlaceholder = () => {
      if (textNoArray.value.length) {
        batchPlaceholder.value = [];
      } else {
        batchPlaceholder.value = ["", "", "", "", "", ""];
      }
    };
    // 设置批量查询modal 的 textNoArray
    const setBatchQueryTextNoArray = () => {
      if (codeModalInf.codeType === "dealerCode") {
        textNoArray.value =
          cleanEmptyEleArray(params.dealerCodeList)[0] === ""
            ? []
            : cleanEmptyEleArray(params.dealerCodeList);
      } else {
        textNoArray.value =
          cleanEmptyEleArray(params.vinNoList)[0] === ""
            ? []
            : cleanEmptyEleArray(params.vinNoList);
      }
    };
    //输入框，用于控制焦点
    const input = ref();
    // 关闭批量查询Modal
    const closeBatchQueryModal = () => {
      params.dealerCode = params.dealerCodeList[0];
      params.vinNo = params.vinNoList[0];
    };
    const handleDelete = (id: number) => {
      modalVisible.value = true;
      modalParams.noticeType = "warning";
      modalParams.content = "Are you sure you want to delete this rule？";
      modalParams.btnVisible = true;
      Object.assign(modalData, modalParams);
      deleteId.value = id;
    };
    const handlView = (record: any) => {
      const params = {
        id: record.id,
      };
      getDelayCallbackDetail({ params }).then((res: any) => {
        nowItemData.value = res;
        viewCove.value = true;
      });
    };
    const handlEdit = (record: any) => {
      const params = {
        id: record.id,
      };
      getDelayCallbackDetail({ params }).then((res: any) => {
        nowItemData.value = res;
        editCove.value = true;
      });
    };

    const handleCancel = () => {
      viewCove.value = false;
      editCove.value = false;
      dealerCove.value = false;
      nowItemData.value = {};
    };
    const handleDealerCancel = () => {
      dealerCove.value = false;
      nowDealerLstItem.value = {
        dealerCodeList: [],
        vinList: [],
        status: "",
      };
    };
    const handleDealerMore = (type: string, data: any) => {
      const params = {
        dealerCodeList: [],
        vinList: [],
        status: type,
        statusText: "",
      };
      if (type === "dealers") {
        params.statusText = "Dealer";
        params.dealerCodeList = data;
      } else if (type === "vin") {
        params.statusText = "VIN";
        params.vinList = data;
      }
      nowDealerLstItem.value = params;
      dealerCove.value = true;
    };
    const handleEditOk = (id: number) => {
      if (editDeadLine.value) {
        const now = new Date();
        const deadline = new Date(editDeadLine.value);
        if (deadline <= now) {
          modalVisible.value = true;
          modalParams.noticeType = "warning";
          modalParams.content =
            "Please select a date later than the current time.";
          modalParams.btnVisible = false;
          Object.assign(modalData, modalParams);
        } else {
          const params = {
            id: id,
            delayDeadline: editDeadLine.value,
          };
          updateDelayCallbackComments(params).then((res: any) => {
            modalVisible.value = true;
            modalParams.noticeType = "success";
            modalParams.content = "Success!";
            modalParams.btnVisible = false;
            Object.assign(modalData, modalParams);
            editCove.value = false;
            editDeadLine.value = "";
            getTableData();
          });
        }
      } else {
        modalVisible.value = true;
        modalParams.noticeType = "warning";
        modalParams.content = "Please choose deferred deadline";
        modalParams.btnVisible = false;
        Object.assign(modalData, modalParams);
      }
    };
    const handleAdd = async () => {
      if (params.comments === "") {
        message.error("Please add delay comments");
        return;
      }
      getParams();
      if (paramsInfo.delayDeadline) {
        const now = new Date();
        const deadline = new Date(paramsInfo.delayDeadline);
        if (deadline <= now) {
          modalVisible.value = true;
          modalParams.noticeType = "warning";
          modalParams.content =
            "Please select a date later than the current time.";
          modalParams.btnVisible = false;
          Object.assign(modalData, modalParams);
        } else {
          const dealerArr = [];
          const vinArr = [];
          if (paramsInfo.dealerCode && paramsInfo.dealerCode.trim()) {
            dealerArr.push(paramsInfo.dealerCode);
          }
          if (paramsInfo.vinNo && paramsInfo.vinNo.trim()) {
            vinArr.push(paramsInfo.vinNo);
          }
          const paramsList = {
            bu: paramsInfo.bu,
            city: paramsInfo.city,
            comments: paramsInfo.comments,
            dealerCodeList:
              paramsInfo.dealerCodeList && paramsInfo.dealerCodeList.length > 0
                ? paramsInfo.dealerCodeList
                : dealerArr,
            delayDeadline: paramsInfo.delayDeadline,
            province: paramsInfo.province,
            region: paramsInfo.region,
            regionName: paramsInfo.regionName,
            retailPeriodFrom: paramsInfo.retailPeriodFrom,
            retailPeriodTo: paramsInfo.retailPeriodTo,
            vinNoList:
              paramsInfo.vinNoList && paramsInfo.vinNoList.length > 0
                ? paramsInfo.vinNoList
                : vinArr,
          };
          try {
            const res = await saveDelayCallbackComments(paramsList);
            if (res.code !== "0") {
              modalVisible.value = true;
              modalParams.noticeType = "error";
              // sessionStorage.setItem("muchContent", JSON.stringify(res.data));
              modalParams.content = res.message;
              modalParams.btnVisible = false;
              Object.assign(modalData, modalParams);
            } else {
              modalVisible.value = true;
              modalParams.noticeType = "success";
              modalParams.content = "Success!";
              modalParams.btnVisible = false;
              Object.assign(modalData, modalParams);
              getTableData();
              resetParams();
            }
          } catch (e) {
            modalVisible.value = true;
            modalParams.noticeType = "error";
            modalParams.content = "Failed！";
            modalParams.btnVisible = false;
            Object.assign(modalData, params);
          }
        }
      } else {
        modalVisible.value = true;
        modalParams.noticeType = "warning";
        modalParams.content = "Please choose deferred deadline";
        modalParams.btnVisible = false;
        Object.assign(modalData, modalParams);
      }
    };
    const handleDealerCodeBtn = (codeType: any) => {
      if (codeType === "dealerCode") {
        if (dealerDisabled.value == false) {
          codeModalInf.isCover = true;
          codeModalInf.codeType = "dealerCode";
          codeModalInf.codeModalTit = "Dealer Code";
          codeModalInf.codeModalLstTit = "Dealer Code";
        }
      } else if (codeType === "vinNo") {
        codeModalInf.isCover = true;
        codeModalInf.codeType = "vinNo";
        codeModalInf.codeModalTit = "VIN";
        codeModalInf.codeModalLstTit = "VIN";
      }
      setBatchQueryTextNoArray();
      setBatchPlaceholder();
      const dom = document.getElementsByClassName("list-content")[0];
      nextTick(() => {
        dom && dom.scrollTo(0, textNoArray.value.length * 36);
        input.value.focus();
      });
    };
    const closeDealerCodeCover = () => {
      params.dealerCode = cleanEmptyEleArray(params.dealerCodeList)[0];
      params.vinNo = cleanEmptyEleArray(params.vinNoList)[0];
    };
    const textChangEvent = () => {
      if (textContentNo.value.length < 5) {
        message.error("请从Excel中粘贴到文本框");
        textContentNo.value = "";
        return;
      }
      textNoArray.value = textNoArray.value.concat(
        textContentNo.value.replace(/\r|\n|\s/g, ",").split(",")
      );
      const dom = document.getElementsByClassName("list-content")[0];
      textContentNo.value = "";
      if (codeModalInf.codeType === "dealerCode") {
        params.dealerCodeList = [];
        params.dealerCodeList = params.dealerCodeList.concat(textNoArray.value);
      } else {
        params.vinNoList = [];
        params.vinNoList = params.vinNoList.concat(textNoArray.value);
      }
      setBatchPlaceholder();
      nextTick(() => {
        dom.scrollTo(0, dom.scrollHeight + 40);
        input.value.focus();
      });
    };
    // 清除批量查询
    const clearBatchQuery = () => {
      if (codeModalInf.codeType === "dealerCode") {
        params.dealerCodeList = [];
      } else {
        params.vinNoList = [];
      }
      setBatchQueryTextNoArray();
      setBatchPlaceholder();
      input.value.focus();
    };

    // 分页逻辑
    const pageChange = (page: number, pageSize: number) => {
      pagination.currentPage = page > 0 ? page - 1 : 0;
      pagination.pageSize = pageSize;
      getTableData();
    };
    const sizeChange = (page: number, pageSize: number) => {
      pagination.currentPage = page > 0 ? page - 1 : 0;
      pagination.pageSize = pageSize;
      getTableData();
    };
    // 关闭信息弹窗
    const closeModal = (param: { isVisible: boolean; continue: boolean }) => {
      modalVisible.value = param.isVisible;
      if (param.continue) {
        const params = {
          id: deleteId.value,
        };
        deleteDelayCallbackComments({ params }).then((res) => {
          modalVisible.value = true;
          modalParams.noticeType = "success";
          modalParams.content = "Delete success!";
          modalParams.btnVisible = false;
          Object.assign(modalData, modalParams);
          getTableData();
        });
        deleteId.value = 0;
        sessionStorage.setItem("continue", "");
      }
    };

    return {
      commentsLastValue,
      clearBatchQuery,
      textContentNo,
      textChangEvent,
      setBatchQueryTextNoArray,
      closeDealerCodeCover,
      codeModalInf,
      handleDealerCodeBtn,
      handleEditOk,
      editDeadLine,
      handlEdit,
      editCove,
      nowCityLst,
      regionAble,
      provinceAble,
      areasArr,
      store,
      getTableData,
      businessUnitSelect,
      doGetSelect,
      dogetRegion,
      regionSelect,
      init,
      handleAdd,
      nowDealerLstItem,
      columnsProgram,
      columnsDealer,
      handleDealerMore,
      handleDealerCancel,
      dealerCove,
      nowItemData,
      handlView,
      handleCancel,
      viewCove,
      handleDelete,
      nowTiem,
      batchPlaceholder,
      input,
      setBatchPlaceholder,
      cleanEmptyEleArray,
      textNoArray,
      cityAble,
      disabledDate,
      getBUForm,
      getRegionForm,
      getForm,
      params,
      paramsInfo,
      searchCon,
      tableHeight,
      queryListData,
      tableWidth,
      columns,
      getParams,
      modalVisible,
      modalData,
      modalParams,
      closeModal,
      nowDate,
      pageChange,
      sizeChange,
      closeBatchQueryModal,
      pagination,
      getTableScroll,
      resetParams,
      deleteId,
      isDealerDisabled,
      dealerDisabled,
    };
  },
});
